export const company = {
  statusId: 1,
  companyId: 1,
  codigoUsuarioMaestro: 1,
};

export const companyForm = {
  id: 1,
};

export const paginationTopLimit = {
  limit: 9,
  topLimit: 999999999,
  limitPage: 1,
};
