const ZoneInMap = [
    {
        id:1,
        region:'Región Metropolitana',
        comuna:'Santiago',
        longitude:-70.66966848671505,
        latitude: -33.44002515016108,
    }
  ]


  export default ZoneInMap;