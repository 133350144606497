export const realtorData = {
  email: 'MMPROP.CHILE@UNNE.CL',
  phone:'56940502381'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'MMPROP.CHILE@UNNE.CL',
  phone:'940502381',
  wsp:'940502381',
}
